import React from 'react';
import DashboardLayout from '../../layouts/dashboard';

const Channels = () => {
    return (
        <DashboardLayout>
        channels 
    </DashboardLayout>
    );
}

export default Channels;
