import React, { useState, Fragment } from 'react';
import bgImg from '../../assets/img/background.png';
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { countries } from './data';
import routePath from '../../utils/constants';

const objectives = [
    {
        id: '1',
        name: 'Publishing/Scheduling',
    },
    {
        id: '2',
        name: 'Reports/Analytics',
    },
    {
        id: '3',
        name: 'Social Monitoring',
    },
    {
        id: '4',
        name: 'Automation',
    }
];
const people = [
    { range: '0 - 20' },
    { range: '20 -  100' },
    { range: '100 -  200' },
    { range: '200 -  1000' }
];

const Onboard = () => {
    const [companySize, setCompanySize] = useState(people[0]);

    return (
        <div
            style={{
                backgroundImage: `url(${bgImg})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundBlendMode: 'multiply'
            }}
            className='flex items-center justify-center h-screen w-full bg-black/10'>
                    <div className='bg-white'>
                        <div className='p-4 bg-primary/50 text-black'>
                            <h1 className='text-2xl font-bold'>Help us know you a little better</h1>
                        </div>
                        <h1 className='p-4 text-xl my-2 font-medium'>What core social media objectives, do you wish to achieve using Weza Social?</h1>
                        <div className='px-2'>
                            {objectives.map(obj => (
                                <button className={`p-2 mx-1 text-sm rounded-full w-fit cursor-pointer border-2 border-gray-300 ${true ? 'bg-black text-white' : 'bg-white text-black'}`} key={obj.id}>
                                    {obj.name}
                                </button>
                            ))}
                        </div>
                        <div className='m-6'>
                            <span className='flex text-sm items-center'>
                                <svg width="20" height="13" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M7.5 8.75C9.91797 8.75 11.875 6.79297 11.875 4.375C11.875 1.95703 9.91797 0 7.5 0C5.08203 0 3.125 1.95703 3.125 4.375C3.125 6.79297 5.08203 8.75 7.5 8.75ZM10.5 10H10.1758C9.36328 10.3906 8.46094 10.625 7.5 10.625C6.53906 10.625 5.64062 10.3906 4.82422 10H4.5C2.01562 10 0 12.0156 0 14.5V15.625C0 16.6602 0.839844 17.5 1.875 17.5H13.125C14.1602 17.5 15 16.6602 15 15.625V14.5C15 12.0156 12.9844 10 10.5 10ZM18.75 8.75C20.8203 8.75 22.5 7.07031 22.5 5C22.5 2.92969 20.8203 1.25 18.75 1.25C16.6797 1.25 15 2.92969 15 5C15 7.07031 16.6797 8.75 18.75 8.75ZM20.625 10H20.4766C19.9336 10.1875 19.3594 10.3125 18.75 10.3125C18.1406 10.3125 17.5664 10.1875 17.0234 10H16.875C16.0781 10 15.3438 10.2305 14.6992 10.6016C15.6523 11.6289 16.25 12.9922 16.25 14.5V16C16.25 16.0859 16.2305 16.168 16.2266 16.25H23.125C24.1602 16.25 25 15.4102 25 14.375C25 11.957 23.043 10 20.625 10Z" fill="black" />
                                </svg>
                                <h1 className='ml-1'>Company Size *</h1>
                            </span>
                            <Listbox value={companySize} onChange={setCompanySize} className="w-1/2 border-2 border-gray-200 rounded-sm">
                                <div className="relative mt-1">
                                    <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                        <span className="block truncate">{companySize.range}</span>
                                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                            <ChevronUpDownIcon
                                                className="h-5 w-5 text-gray-400"
                                                aria-hidden="true"
                                            />
                                        </span>
                                    </Listbox.Button>
                                    <Transition
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                            {people.map((person, personIdx) => (
                                                <Listbox.Option
                                                    key={personIdx}
                                                    className={({ active }) =>
                                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                                                        }`
                                                    }
                                                    value={person}
                                                >
                                                    {({ companySize }) => (
                                                        <>
                                                            <span
                                                                className={`block truncate ${companySize ? 'font-medium' : 'font-normal'
                                                                    }`}
                                                            >
                                                                {person.range}
                                                            </span>
                                                            {companySize ? (
                                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                </span>
                                                            ) : null}
                                                        </>
                                                    )}
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </Transition>
                                </div>
                            </Listbox>
                        </div>
                        <div className="m-6">
                            <span className='flex text-sm items-center'>
                                <svg width="13" height="13" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19.4296 14.1327L15.0546 12.2577C14.8677 12.178 14.6599 12.1612 14.4627 12.2098C14.2654 12.2585 14.0893 12.3698 13.9608 12.5272L12.0233 14.8944C8.98248 13.4607 6.53535 11.0136 5.10165 7.97283L7.46888 6.0353C7.62657 5.90704 7.73818 5.73091 7.78681 5.53355C7.83545 5.33619 7.81846 5.12837 7.73842 4.94153L5.86338 0.566453C5.77554 0.365046 5.62016 0.200605 5.42406 0.101483C5.22796 0.00236143 5.00341 -0.0252272 4.78915 0.0234743L0.726575 0.960991C0.519997 1.00869 0.335688 1.12501 0.203728 1.29095C0.0717689 1.45689 -4.75872e-05 1.66266 2.36575e-08 1.87468C2.36575e-08 11.8944 8.12123 20 18.1253 20C18.3374 20.0001 18.5433 19.9284 18.7093 19.7964C18.8753 19.6644 18.9917 19.4801 19.0394 19.2734L19.9769 15.2108C20.0253 14.9955 19.9971 14.7701 19.8972 14.5733C19.7973 14.3765 19.632 14.2207 19.4296 14.1327Z" fill="black" />
                                </svg>
                                <h1 className='ml-2'>Phone Number *</h1>
                            </span>
                            <div className="border-2 border-gray rounded-lg w-1/2 p-2">
                                <select className='focus:outline-none'>
                                    {countries.map((country) => (
                                        <option key={country.name} value={country.code}>
                                            {`${country.name} (${country.code})`}
                                        </option>
                                    ))}
                                </select>
                                <input type="number" placeholder="Phone number" className="ml-2 focus:outline-none" />
                            </div>
                        </div>
                        <div className='flex justify-end border-t-2 border-black p-4'>
                            <a href={routePath.CREATEBRAND} className='uppercase text-white bg-primary py-2 px-8 rounded-full hover:scale-105 font-bold'>
                                Take me in
                            </a>
                        </div>
                    </div>
        </div>
    );
};

export default Onboard;