import React from 'react';
import Logo from '../../assets/img/logo-full.png';
import bgImage from '../../assets/img/lp-bg.png';

const GetStarted = () => {
    return (
        <section className='h-screen w-full flex xs:flex-col lg:flex-row items-center'>
            <div
                style={{
                    backgroundImage: `url(${bgImage})`,
                    backgroundBlendMode: 'lighten',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }}
                className='lg:w-[33%] bg-black h-screen text-white flex justify-center flex-col px-4'>
                <h1 className='lg:text-4xl lg:mb-10 xs:mb-4 font-bold xs:text-xl xs:py-2'>Manage All your social Media platforms with Weza Social</h1>
                <p className='lg:text-lg xs:text-sm xs:py-2'>create an account and get started now.</p>
            </div>
            <div className='lg:px-16 xs:px-4 lg:w-[60%]'>
                <img src={Logo} alt="" className='my-10' />
                <h1 className='text-3xl font-bold my-4'>Get Started in 5 seconds</h1>
                <form className='lg:w-10/12 xs:w-11/12 mt-10'>
                    <div className='px-2 py-4 border-2 border-black rounded-sm my-4'>
                        <input className='w-full focus:outline-none' type='text' name="name" placeholder='Enter your name *' required />
                    </div>
                    <div className='px-2 py-4 border-2 border-black rounded-sm my-4'>
                        <input className='w-full focus:outline-none' type='email' name="name" placeholder='Enter your email *' required />
                    </div>
                    <div className='px-2 py-4 border-2 border-black rounded-sm my-4'>
                        <input className='w-full focus:outline-none' type='password' name="name" placeholder='Create password *' required />
                    </div>
                    <div className='px-2 py-4 border-2 border-black rounded-sm my-4'>
                        <input className='w-full focus:outline-none' type='password' name="name" placeholder='Confirm Password *' required />
                    </div>
                    <div className='flex items-center'>
                        <input type='checkbox' className='' />
                        <p className='ml-2 text-sm'>I agree to the <a href="/" className='underline'>Terms of service</a> and <a className='underline' href="/">privacy policy</a></p>
                    </div>
                    <div className='mt-5'>
                        <input type='submit' className='text-center w-full py-4 bg-primary font-bold text-white hover:bg-primary/90 cursor-pointer' value='START YOUR TRIAL' />
                    </div>
                </form>
                <p className='lg:my-10 text-center w-10/12 font-bold'>Or</p>
                <a className='my-2 text-primary flex items-center justify-center border-2 rounded-sm border-primary cursor-pointer py-2 lg:w-10/12 xs:w-11/12 hover:bg-primary/70 hover:text-white' href="/dashboard">
                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M23.8281 12.3926C23.8281 19.3018 19.0967 24.2188 12.1094 24.2188C5.41016 24.2188 0 18.8086 0 12.1094C0 5.41016 5.41016 0 12.1094 0C15.3711 0 18.1152 1.19629 20.2295 3.16895L16.9336 6.33789C12.6221 2.17773 4.60449 5.30273 4.60449 12.1094C4.60449 16.333 7.97852 19.7559 12.1094 19.7559C16.9043 19.7559 18.7012 16.3184 18.9844 14.5361H12.1094V10.3711H23.6377C23.75 10.9912 23.8281 11.5869 23.8281 12.3926Z" fill="#ED262D" />
                    </svg>
                    <h1 className='text-lg font-bold ml-2'>Sign up with Google</h1>
                </a>
            </div>

        </section>
    );
};

export default GetStarted;