import React from 'react';
import DashboardLayout from '../../layouts/dashboard';

const Reports = () => {
    return (
        <DashboardLayout>
        reports
    </DashboardLayout>
    );
}

export default Reports;
