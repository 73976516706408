import React from 'react';
import Header from '../../components/Header';
import Schedule from '../../components/Schedule';
import Publishing from '../../components/Publishing';
import Testmonials from '../../components/Testimonials';
import CaseStudies from '../../components/CaseStudies';
import Lines from '../../components/Lines';
import Hero from '../../components/Hero';
import Footer from '../../components/Footer';
import './style.css';

const LandingPage = () => {
  return (
    <div className="w-full h-auto bg-main">
      <Lines />
      <Header />
      <Hero />
      <Schedule />
      <Publishing />
      <CaseStudies />
      <Testmonials />
      <Footer />
    </div>
  );
};

export default LandingPage;
