import React from 'react';
import DashboardLayout from '../../layouts/dashboard';
import { Tooltip } from 'react-tooltip';
import { FaYoutube, FaInstagram, FaTwitter, FaTiktok, FaFacebook, FaLinkedin, FaGoogle, FaPinterest } from 'react-icons/fa';
import NoPostImg from '../../assets/img/recent-posts.png';

const Dashboard = () => {
    return (
        <DashboardLayout>
            <div className="flex h-screen bg-gray-100">
                <div className="w-8/12 h-full">
                    <div className="">
                        <div className="my-8 ml-10 bg-white p-4 rounded-sm shadow">
                            <h1 className="text-xl font-bold mb-4">Brand Health</h1>
                            <table className="w-full">
                                <thead>
                                    <tr className=''>
                                        <th className="text-start py-2 text-sm">Channels</th>
                                        <th className="text-start py-2 text-sm">
                                            <div className='flex'>
                                                Total Followers
                                                <div className=''>
                                                    <span
                                                        data-tooltip-id="t-followers"
                                                        data-tooltip-content="Total count of your fans/audience in your channels with a decrease/increase in the last 30 days."
                                                        className="text-blue-500 cursor-pointer"
                                                    >
                                                        <sup className="cursor-help">?</sup>
                                                    </span>
                                                    <Tooltip id="t-followers" place="top" effect="solid" />
                                                </div>
                                            </div>
                                        </th>
                                        <th className="text-start py-2 text-sm">
                                            <div className='flex'>
                                                New Followers
                                                <div>
                                                    <span
                                                        data-tooltip-id="n-followers"
                                                        data-tooltip-content="New fans/audience for your channels for the past 30 days."
                                                        className="text-blue-500 cursor-pointer text-sm"
                                                    >
                                                        <sup className='cursor-help'>?</sup>
                                                    </span>
                                                    <Tooltip id="n-followers" place="top" effect="solid" />
                                                </div>
                                            </div>
                                        </th>
                                        <th className="text-start py-2 text-sm">
                                            <div className='flex'>
                                                No of Posts
                                                <div>
                                                    <span
                                                        data-tooltip-id="posts"
                                                        data-tooltip-content="Total Number of posts published on your channels for the past 30 days."
                                                        className="text-blue-500 cursor-pointer text-sm"
                                                    >
                                                        <sup className='cursor-help'>?</sup>
                                                    </span>
                                                    <Tooltip id="posts" place="top" effect="solid" />
                                                </div>
                                            </div>
                                        </th>
                                        <th className="text-start py-2 text-sm">
                                            <div className='flex'>
                                                Reach
                                                <div>
                                                    <span
                                                        data-tooltip-id="reach"
                                                        data-tooltip-content="Total number of unique views for your posts in the past 30 days."
                                                        className="text-blue-500 cursor-pointer text-sm"
                                                    >
                                                        <sup className='cursor-help'>?</sup>
                                                    </span>
                                                    <Tooltip id="reach" place="top" effect="solid" />
                                                </div>
                                            </div>
                                        </th>
                                        <th className="text-start py-2 text-sm">
                                            <div className='flex'>
                                                Engagement
                                                <div>
                                                    <span
                                                        data-tooltip-id="engagement"
                                                        data-tooltip-content="Total number of engagements received such as likes,comments and shares in the past 30 days."
                                                        className="text-blue-500 cursor-pointer text-sm"
                                                    >
                                                        <sup className='cursor-help'>?</sup>
                                                    </span>
                                                    <Tooltip id="engagement" place="top" effect="solid" />
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="px-4 py-2 border-2 border-gray-100">
                                            <div className='flex items-center'>
                                                <FaYoutube className="text-sm text-primary mr-1" /> TOONIFY
                                            </div>
                                        </td>
                                        <td className="px-4 py-2 border-2 border-gray-100">0</td>
                                        <td className="px-4 py-2 border-2 border-gray-100">0</td>
                                        <td className="px-4 py-2 border-2 border-gray-100">0</td>
                                        <td className="px-4 py-2 border-2 border-gray-100">0</td>
                                        <td className="px-4 py-2 border-2 border-gray-100">0</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="flex justify-end mb-4 mr-2 items-center space-x-2">
                        <p className="mr-2 text-sm">Add more channels</p>
                        <FaFacebook  className='hover:scale-125 hover:text-primary cursor-pointer'/>
                        <FaTwitter  className='hover:scale-125 hover:text-primary cursor-pointer'/>
                        <FaLinkedin  className='hover:scale-125 hover:text-primary cursor-pointer'/>
                        <FaInstagram  className='hover:scale-125 hover:text-primary cursor-pointer'/>
                        <FaGoogle  className='hover:scale-125 hover:text-primary cursor-pointer'/>
                        <FaPinterest className='hover:scale-125 hover:text-primary cursor-pointer' />
                        <FaTiktok  className='hover:scale-125 hover:text-primary cursor-pointer'/>
                    </div>
                    <div className="bg-white p-4 rounded-sm shadow ml-10 h-1/2">
                        <h1 className="text-xl font-bold mb-4">Recent posts</h1>
                        {/* Recent posts */}
                        <div className='my-10 flex flex-col justify-center items-center'>
                            <img src={NoPostImg} className='w-44' alt=''/>
                            <p className='text-sm'>Add more social channels to view recent posts from them.</p>
                        </div>
                    </div>
                </div>
                <div className="w-1/3 bg-gray-100 p-4 h-full">
                    <div className="bg-white p-4 rounded-sm shadow my-4 h-[95%]">
                        <h1 className="text-xl font-bold mb-2">Live stream</h1>
                        <p>Here's where you'll see activity updates from across social networks.</p>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    );
};

export default Dashboard;