import React, { useState } from 'react';
import DashboardLayout from '../../layouts/dashboard';
import { FaPaperPlane, FaYoutube, FaFacebook, FaTwitter, FaClock, FaExclamationTriangle, FaFileExcel, FaCalendar, FaStackOverflow } from 'react-icons/fa';
import './posts.css';


const sideMenuLinks = [
    {
        id: 1,
        name: 'Published posts',
        icon: <FaPaperPlane />,
    },
    {
        id: 2,
        name: 'Scheduled posts',
        icon: <FaClock />,
    },
    {
        id: 3,
        name: 'UnPublished posts',
        icon: <FaExclamationTriangle />,
    },
    {
        id: 4,
        name: 'Drafts',
        icon: <FaFileExcel />,
    },
    {
        id: 5,
        name: 'Calendar',
        icon: <FaCalendar />,
    },
    {
        id: 6,
        name: 'Media Library',
        icon: <FaStackOverflow />,
    }
];
const channels = [
    {
        id: 1,
        icon: FaYoutube,
        class: 'text-primary'
    },
    {
        id: 2,
        icon: FaTwitter,
        class: 'text-[#00acee]'
    },
    {
        id: 3,
        icon: FaFacebook,
        class: 'text-[#3b5998]'
    }
]

const Posts = () => {
    const [activeTab, setActiveTab] = useState(sideMenuLinks[0]);
    const [selectedChannel, setSelectedChannel] = useState(channels[0]);

    return (
        <DashboardLayout>
            <section className='bg-gray-100 mx-10 drop-shadow-sm rounded-sm p-4 flex h-screen'>
                <div className='py-10 pl-5 bg-white border-r-2 border-gray-300 w-[20%]'>
                    {sideMenuLinks.map((lnk,index) => (
                        <>
                        <div className={`flex pl-2 py-1 items-center my-4 cursor-pointer hover:text-black hover:bg-secondary/40 ${lnk.id === activeTab.id ? 'bg-secondary/40 border-l-4 border-secondary text-black' : 'text-gray-500'}`} onClick={() => setActiveTab(lnk)}>
                            <span>{lnk.icon}</span>
                            <span className='ml-2'>{lnk.name}</span>
                        </div>
                        {index === 4 && <hr />}
                        </>
                    ))}
                </div>
                <div className='bg-white w-[80%]'>
                    <div className="flex items-center justify-start space-x-4 pl-4 border-b-2 border-gray-200 py-1">
                        {channels.map(chan => (
                        <div className={`${chan.id === selectedChannel.id?'border-b-2 border-secondary pb-2':''}`} key={chan.id}>
                            <div className={`relative flex items-center justify-center rounded-full w-8 h-8 cursor-pointer ${chan.id === selectedChannel.id?'ch':''}`} onClick={() => setSelectedChannel(chan)}>
                                <chan.icon className={`${chan.class} ${chan.id === selectedChannel.id?'sc-icon':''}`} />
                            </div>
                        </div>
                        ))}
                    </div>
                    <div className=''>
                        <table class="min-w-full bg-white">
                            <thead>
                                <tr>
                                    <th class="py-1 px-4 bg-gray-300 text-sm text-black font-bold text-start">PUBLISHED ON</th>
                                    <th class="py-1 px-4 bg-gray-300 text-sm text-black font-bold text-start">POST CONTENT</th>
                                    <th class="py-1 px-4 bg-gray-300 text-sm text-black font-bold text-start">INTERACTION</th>
                                    <th class="py-1 px-4 bg-gray-300 text-sm text-black font-bold text-start">PUBLISHED BY</th>
                                    <th class="py-1 px-4 bg-gray-300 text-sm text-black font-bold text-start">ACTION</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='border-b-2 border-gray-200'>
                                    <td class="py-2 px-4">03 May 2023 11:15PM</td>
                                    <td class="py-2 px-4">Lorem ipsum dolor sit amet</td>
                                    <td class="py-2 px-4">Likes: 50, Comments: 10</td>
                                    <td class="py-2 px-4">John Doe</td>
                                    <td class="py-2 px-4">
                                        <button class="flex items-center justify-center text-gray-500 hover:text-gray-700">
                                            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
                                                <path d="M2 10a2 2 0 114 0 2 2 0 01-4 0zm12 0a2 2 0 114 0 2 2 0 01-4 0zm-6 0a2 2 0 114 0 2 2 0 01-4 0z"></path>
                                            </svg>
                                        </button>
                                    </td>
                                </tr>
                                <tr className='border-b-2 border-gray-200'>
                                    <td class="py-2 px-4">03 May 2023 11:15PM</td>
                                    <td class="py-2 px-4">Dolor sit amet consectetur</td>
                                    <td class="py-2 px-4">Likes: 30, Comments: 5</td>
                                    <td class="py-2 px-4">Jane Smith</td>
                                    <td class="py-2 px-4">
                                        <button class="flex items-center justify-center text-gray-500 hover:text-gray-700">
                                            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
                                                <path d="M2 10a2 2 0 114 0 2 2 0 01-4 0zm12 0a2 2 0 114 0 2 2 0 01-4 0zm-6 0a2 2 0 114 0 2 2 0 01-4 0z"></path>
                                            </svg>
                                        </button>
                                    </td>
                                </tr>
                                <tr className='border-b-2 border-gray-200'>
                                    <td class="py-2 px-4">03 May 2023 11:15PM</td>
                                    <td class="py-2 px-4">Lorem ipsum dolor sit amet consectetur adipisicing elit</td>
                                    <td class="py-2 px-4">Likes: 20, Comments: 3</td>
                                    <td class="py-2 px-4">Mike Johnson</td>
                                    <td class="py-2 px-4">
                                        <button class="flex items-center justify-center text-gray-500 hover:text-gray-700">
                                            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
                                                <path d="M2 10a2 2 0 114 0 2 2 0 01-4 0zm12 0a2 2 0 114 0 2 2 0 01-4 0zm-6 0a2 2 0 114 0 2 2 0 01-4 0z"></path>
                                            </svg>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>


            </section>

        </DashboardLayout>
    );
}



export default Posts;
