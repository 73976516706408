import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from '../pages/LandingPage';
import GetStarted from '../pages/GetStarted';
import routePath from '../utils/constants';
import Onboard from '../pages/Onboard';
import Dashboard from '../pages/Dashboard';
import NewBrand from '../pages/Brand';
import Posts from '../pages/Posts';
import Messages from '../pages/Messages';
import Channels from '../pages/Channels';
import Monitor from '../pages/Monitor';
import Reports from '../pages/Reports';
import Teams from '../pages/Team';

export const Routing = () => {
    return (
        <Router>
            <Routes>
                <Route path={routePath.HOME} element={<LandingPage />} />
                <Route path={routePath.GETSTARTED} element={<GetStarted />} />
                <Route path={routePath.ONBOARD} element={<Onboard />} />
                <Route path={routePath.CREATEBRAND} element={<NewBrand />} />
                <Route path={routePath.DASHBOARD} element={<Dashboard />} />
                <Route path={routePath.POSTS} element={<Posts />} />
                <Route path={routePath.MESSAGES} element={<Messages />} />
                <Route path={routePath.CHANNELS} element={<Channels />} />
                <Route path={routePath.MONITOR} element={<Monitor />} />
                <Route path={routePath.REPORTS} element={<Reports />} />
                <Route path={routePath.TEAM} element={<Teams />} />
                <Route path="*" element={<LandingPage />} />
            </Routes>
        </Router>
    )
}
