import React from 'react';
import NavBar from '../components/NavBar';

const DashboardLayout = ({ children }) => {
    return (
        <section>
            <NavBar />
            {children}
            <div className='py-1 bg-black text-center text-white fixed bottom-0 right-0 left-0'>
                <p className='text-sm font-thin '>Designed & Developed by <a href="https://wezaprosoft.com" className='hover:underline'>Weza Prosoft</a></p>
            </div>
        </section>
    );
};

export default DashboardLayout;
