import React, { useState } from 'react';
import { FaChevronDown, FaBell, FaCog, FaQuestionCircle } from 'react-icons/fa';
import { createAvatar } from '../../utils/generateAvatar';
import Drawer from '../../components/AccountsDrawer';
import routePath from '../../utils/constants';
import { useLocation } from 'react-router-dom';


const brands = [
    {
        id: '1',
        name: 'WEZA BRAND',
    },
    {
        id: '2',
        name: 'Second BRAND Second BRAND Second BRAND Second BRAND ',
    }
];

const navigationLinks = [
    {
        id: '1',
        name: 'Home',
        url: routePath.DASHBOARD
    },
    {
        id: '2',
        name: 'Posts',
        url: routePath.POSTS
    },
    {
        id: '3',
        name: 'Messages',
        url: routePath.MESSAGES
    },
    {
        id: '4',
        name: 'Channels',
        url: routePath.CHANNELS
    },
    {
        id: '5',
        name: 'Monitor',
        url: routePath.MONITOR
    },
    {
        id: '6',
        name: 'Reports',
        url: routePath.REPORTS
    },
    {
        id: '7',
        name: 'Team',
        url: routePath.TEAM
    }
];


const Navbar = () => {
    const [selectedBrand, setSelectedBrand] = useState(brands[0]);
    const [showBrandDropdown, setShowBrandDropdown] = useState(false);
    const [newPostOptions, setShowNewPostOptions] = useState(false);
    const [openAccountsDrawer, setOpenAccountsDrawer] = useState(false);
    const [subscriptionsState] = useState('trial');

    const location = useLocation();

    const handleBrandSelection = (br) => {
        setSelectedBrand(br);
        setShowBrandDropdown(false);
    }
    const createNewBrand = () => {
        setShowBrandDropdown(false);
    }
    const createNewPost = (option) => {
        setShowNewPostOptions(false);
        setShowBrandDropdown(false);
    }
    const handleAccountsDrawerClose = () => {
        setOpenAccountsDrawer(false);
        setShowBrandDropdown(false);
        setShowNewPostOptions(false);
    };

    return (
        <section className='bg-gray-100'>
            <nav className="bg-secondary">
                <div className="flex justify-between items-center">
                    {/* First column */}
                    <div className="flex items-center space-x-2 bg-black/80 py-2 pl-10 pr-5">
                        {/* <div className="bg-white rounded-full h-8 w-8"></div> */}
                        <div className="text-white flex items-center" onClick={() => setShowBrandDropdown(!showBrandDropdown)}>
                            <img alt="brand" className='mr-2' src={createAvatar(selectedBrand.name.charAt(0), 50)} />
                            {selectedBrand.name.length > 15 ? selectedBrand.name.slice(0, 14) : selectedBrand.name}
                        </div>
                        <button className="text-white focus:outline-none" onClick={() => setShowBrandDropdown(!showBrandDropdown)}>
                            <FaChevronDown className="h-5 w-5" />
                        </button>
                        {
                            showBrandDropdown &&
                            <div className="absolute left-2 top-15 w-[14%] rounded shadow-lg  bg-black/90 p-4 z-10">
                                {brands.map(br => (
                                    <div key={br.id} className='text-start drop-shadow-md hover:scale-105'>
                                        <h1 className='text-sm bg-white rounded p-2 cursor-pointer my-2' onClick={() => handleBrandSelection(br)}>{br.name}</h1>
                                    </div>
                                ))}
                                <button className="px-4 py-2 text-white bg-primary rounded hover:bg-primary/90 w-full text-center hover:font-bold" onClick={createNewBrand}>
                                    Create New Brand
                                </button>
                            </div>
                        }
                    </div>
                    {/* Second column */}
                    <div className="flex justify-center space-x-4">
                        {navigationLinks.map(nv => (
                            <a key={nv.id} style={{ textUnderlineOffset: 4 }} href={nv.url} className={`decoration-2 font-bold hover:underline hover:scale-105 ${nv.url === location.pathname ? 'font-bold underline text-white' : 'text-gray-200'}`}>{nv.name}</a>
                        ))}
                    </div>
                    {/* Third column */}
                    <div className="flex items-center space-x-4 pr-10">
                        {/* New post dropdown */}
                        <div className="relative">
                            <button className="text-white hover:text-gray-200 focus:outline-none bg-black rounded-full px-6 flex items-center" onClick={() => setShowNewPostOptions(!newPostOptions)}>
                                New Post
                                <FaChevronDown className="ml-2" />
                            </button>
                            {newPostOptions &&
                                <div className="absolute right-0 mt-2 w-48 bg-white rounded shadow-lg">
                                    <button className="block px-4 py-2 text-gray-800 hover:bg-gray-200 w-full text-left" onClick={() => createNewPost('ai')}>
                                        Compose with AI
                                    </button>
                                    <button className="block px-4 py-2 text-gray-800 hover:bg-gray-200 w-full text-left" onClick={() => createNewPost('ig')}>
                                        Instagram Stories
                                    </button>
                                    <button className="block px-4 py-2 text-gray-800 hover:bg-gray-200 w-full text-left" onClick={() => createNewPost('fb')}>
                                        Facebook Stories
                                    </button>
                                    <button className="block px-4 py-2 text-gray-800 hover:bg-gray-200 w-full text-left" onClick={() => createNewPost('sc')}>
                                        Bulk Schedule
                                    </button>
                                </div>
                            }
                        </div>
                        <button className="text-white hover:text-gray-200 focus:outline-none text-xl hover:scale-105">
                            <FaQuestionCircle />
                        </button>
                        <button className="text-white hover:text-gray-200 focus:outline-none text-xl hover:scale-105">
                            <FaBell />
                        </button>
                        <button className="text-white hover:text-gray-200 focus:outline-none text-xl hover:scale-105">
                            <FaCog />
                        </button>
                        <img className='rounded-full mr-2' src={createAvatar('S', 50)} alt="user" onClick={() => setOpenAccountsDrawer(!openAccountsDrawer)} />
                    </div>
                </div>
            </nav>
            {/* Subscription status */}
            {subscriptionsState === 'trial' &&
                <div className='text-center bg-primary text-white my-2 mx-10'>
                    <p className='text-sm py-1 font-semibold'>
                        You are on: Premium Trial.   Your trial Ends in  15 Days   <span className='text-black font-bold uppercase underline ml-2 cursor-pointer'>UPGRADE</span>
                    </p>
                </div>
            }

            {/* Accounts Drawer */}
            <Drawer isOpen={openAccountsDrawer} onClose={handleAccountsDrawerClose} />

            {/* Notification Drawer */}
            {/* Support Drawer */}

        </section>
    );
};

export default Navbar;
