import React from 'react';
import Logo from '../../assets/img/logo-full.png';

const features = [
  { name: 'Scheduling & Publishing', href: '#' },
  { name: 'Monitoring', href: '#' },
  { name: 'Analytics', href: '#' },
  { name: 'Collaboration', href: '#' },
  { name: 'Integrations', href: '#' },
  { name: 'Facebook Lead Ads', href: '#' },
  { name: 'LinkedIn Lead Ads', href: '#' },
  { name: 'Mobile', href: '#' },
];

const channels = [
  {name: 'Facebook', href:'#'},
  {name: 'Twitter', href:'#'},
  {name: 'LinkedIn', href:'#'},
  {name: 'Instagram', href:'#'},
  {name: 'Youtube', href:'#'},
  {name: 'Pinterest', href:'#'},
  {name: 'Google My Business', href:'#'},
  {name: 'Tiktok', href:'#'}
];

const resources = [
  {name: 'User Guide', href:'#'},
  {name: 'Blogs', href:'#'},
  {name: 'Case studies', href:'#'},
  {name: 'Videos', href:'#'},
  {name: 'Community', href:'#'},
  {name: 'FAQs', href:'#'}
]

const Footer = () => {
  return (
    <footer className="bg-black">
      <div className="container mx-auto py-8 lg:flex">
        {/* Column 1 */}
        <div className="mb-6 lg:w-1/3 lg:mb-0">
          <img className="w-32 h-auto mb-4" src={Logo} alt="Company" />
          <p className="text-white w-80 text-sm mt-4">Schedule unlimited posts, monitor what matters, and create custom-reports to analyze your social media performance with Weza Social</p>
        </div>
        {/* Column 2 */}
        <div className="mb-6 lg:w-1/4 lg:mb-0">
          <h4 className="text-white mb-2 font-bold">Features</h4>
          <ul className="text-white mt-8">
            {features && features.map((ft) => (
            <li className="mb-2 hover:text-primary transition-colors duration-300 text-sm" key={ft.name}>
              <a href={ft.href}>{ft.name}</a>
            </li>
            ))}
          </ul>
        </div>
        {/* Column 3 */}
        <div className="mb-6 lg:w-1/4 lg:mb-0">
          <h4 className="text-white mb-2 font-bold">Channels</h4>
          <ul className="text-white mt-8">
            {channels && channels.map((ch) => (
            <li className="mb-2 hover:text-primary transition-colors duration-300 text-sm" key={ch.name}>
              <a href={ch.href}>{ch.name}</a>
            </li>
            ))}
          </ul>
        </div>
        {/* Column 4 */}
        <div className="mb-6 lg:w-1/4 lg:mb-0">
          <h4 className="text-white mb-2 font-bold">Resources</h4>
          <ul className="text-white mt-8">
            {resources && resources.map((r) => (
            <li className="mb-2 hover:text-primary transition-colors duration-300 text-sm" key={r.name}>
              <a href={r.href}>{r.name}</a>
            </li>
            ))}
          </ul>
        </div>
      </div>
      <hr className="border-white" />
    </footer>
  );
};

export default Footer;
