import React from 'react'
import routePath from '../../utils/constants';
import bgImg from '../../assets/img/background.png';



export default function NewBrand() {

    return (
        <div
            style={{
                backgroundImage: `url(${bgImg})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundBlendMode: 'multiply'
            }}
            className='flex items-center justify-center h-screen w-full bg-black/10'>
            <div className='bg-white'>
                <div className='p-4 bg-primary/50 text-black'>
                    <h1 className='text-2xl font-bold'>Get started by creating your first brand</h1>
                </div>
                <h1 className='p-4 text-xl my-4 font-medium'>A Brand is a collection of social media channels that are all managed through a single dashboard. <br /> You can add one of each type of channel to a Brand</h1>
                <div className='m-6 border-2 border-gray w-1/2 rounded-lg p-4 my-16'>
                    <input type='text' name="brand" placeholder='Enter brand name' className='focus:outline-none w-full' />
                </div>
                <div className='flex justify-end border-t-2 border-black p-4'>
                    <a href={routePath.DASHBOARD} className='uppercase text-white bg-[green] py-2 px-8 rounded-full hover:scale-105 font-bold'>
                        Done
                    </a>
                </div>
            </div>
        </div>
    )
}
