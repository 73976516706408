import { ArrowPathIcon, CloudArrowUpIcon, FingerPrintIcon, LockClosedIcon } from '@heroicons/react/24/outline'

const features = [
  {
    name: 'Push to deploy',
    description:
      'Streamline your deployment process with our push to deploy feature. Say goodbye to manual updates and enjoy seamless and efficient deployments with just a click of a button.',
    icon: CloudArrowUpIcon,
  },
  {
    name: 'SSL certificates',
    description:
      "Protect your users' data and build trust with SSL certificates. Secure your website or application with encrypted connections, ensuring a safe and secure browsing experience",
    icon: LockClosedIcon,
  },
  {
    name: 'Simple queues',
    description:
      "Simplify your workflow with our simple queues feature. Easily manage tasks and prioritize work in an organized and efficient manner, ensuring smooth operations and improved productivity.",
    icon: ArrowPathIcon,
  },
  {
    name: 'Advanced security',
    description:
      "Safeguard your application with advanced security measures. From robust authentication protocols to proactive threat detection, we prioritize the protection of your data and ensure the highest level of security for your application.",
    icon: FingerPrintIcon,
  },
]

export default function Publishing() {
  return (
    <div className="bg-transparent py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-white/80">Deploy faster</h2>
          <p className="mt-2 lg:text-6xl font-bold tracking-tight text-white xs:text-4xl">
            Everything you need to get your content up and running
          </p>
          <p className="mt-6 text-md leading-8 text-white/80 w-9/12 mx-auto">
            Discover everything you need to bring your application to life and get it up and running effortlessly. From comprehensive documentation to intuitive tools and reliable support, we've got you covered at every step of the way. Start building your dream application with confidence and ease using our all-in-one solution
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-white">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-indigo-600">
                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-sm leading-7 text-white/70">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
