import React from 'react';
import DashboardLayout from '../../layouts/dashboard';


const Messages = () => {
    return (
        <DashboardLayout>
            messages
        </DashboardLayout>
    );
}

export default Messages;
