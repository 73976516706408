import React from 'react';
import DashboardLayout from '../../layouts/dashboard';

const Monitor = () => {
    return (
        <DashboardLayout>
            monitoring
        </DashboardLayout>
    );
}

export default Monitor;
