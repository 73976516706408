import React from 'react'

export default function Lines() {
    return (
        <div className="fixed top-0 left-0 bottom-80 z-100 flex  w-full items-center justify-around ">
            <span style={{height:'50%'}} className="w-[1px] h-60 bg-stroke/10 flex animate-line1 dark:bg-strokedark"></span>
            <span style={{height:'50%'}} className="w-[1px] h-60 bg-stroke/10 flex animate-line2 dark:bg-strokedark"></span>
            <span style={{height:'50%'}} className="w-[1px] bg-stroke/10 dark:bg-strokedark flex animate-line3"></span>
        </div>
    )
}
