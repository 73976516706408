import React from 'react';
import DashboardLayout from '../../layouts/dashboard';

const Teams = () => {
    return (
        <DashboardLayout>
            teams
        </DashboardLayout>
    );
}

export default Teams;
