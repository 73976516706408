import React from 'react';
import { motion } from 'framer-motion';
import { createAvatar } from '../../utils/generateAvatar';
import { FaTimesCircle, FaSignOutAlt } from 'react-icons/fa';
import InviteImg from '../../assets/img/invite.png';
import Appstore from '../../assets/img/appstore.png';
import PlayStore from '../../assets/img/playstore.png';
import GExtension from '../../assets/img/gextnesion.png';


const team = [

]

const Drawer = ({ isOpen, onClose }) => {
    const drawerVariants = {
        open: { x: 0 },
        closed: { x: '100%' },
    };

    const textVariants = {
        open: { x: 0, opacity: 1 },
    closed: { x: '100%', opacity: 0 },
    };

    return (
        <motion.div
            className={`h-screen absolute right-0 top-16 bottom-0 left-0 bg-black/40 z-10 flex flex-col items-end ${isOpen ? 'block' : 'hidden'}`}
            initial="closed"
            animate={isOpen ? 'open' : 'closed'}
            variants={drawerVariants}
            transition={{ duration: 0.3 }}
        >

                <motion.div
                 className='h-full bg-gray-100'
                 initial="closed"
                 animate={isOpen ? 'open' : 'closed'}
                 variants={textVariants}
                 transition={{ duration: 0.3, delay: 0.1 }}
                 >
                    <div className="flex justify-end py-4">
                        <button className="text-primary text-3xl mr-6 hover:scale-110" onClick={onClose}>
                            <FaTimesCircle />
                        </button>
                    </div>
                    <div className='flex w-11/12 mx-auto justify-start mt-6 items-center'>
                        <div className='px-4'>
                            <img className='rounded-full mr-2' src={createAvatar('S', 80)} alt="user" />
                        </div>
                        <div>
                            <h1 className='text-lg'>Steven G.</h1>
                            <h1 className='text-sm'>steveng@example.com</h1>
                            <h1 className='text-sm text-primary flex items-center mt-2 cursor-pointer hover:scale-105'>Sign Out <FaSignOutAlt className='ml-2' /></h1>
                        </div>
                    </div>
                    <div className='bg-white drop-shadow-sm w-11/12 mx-auto my-4 p-4 rounded'>
                        <h1 className="my-2 text-sm">You're currently on: <span className='px-2 text-sm rounded-md text-green-700 font-semibold bg-green-100'>Premium Trial</span></h1>
                        <h1 className='my-2 text-black/50 font-semibold'>Trial Expires in 15 Days </h1>
                        <h1 className='my-4 text-primary text-sm'><a href='/' className='hover:underline hover:scale-105'>Try other editions  </a><span className='mx-2'>|</span><a href="/" className='hover:underline'>Manage Subscription </a> </h1>
                    </div>
                    <div className='bg-white drop-shadow-sm rounded w-11/12 mx-auto mt-4 p-4'>
                        <div className='flex justify-between items-center'>
                            <h1>My Team</h1>
                            <span className='text-primary cursor-pointer hover:underline hover:scale-105'>Invite</span>
                        </div>
                        {team.length > 0 ?
                            <div></div>
                            :
                            <div className='flex items-center flex-col py-4 my-5'>
                                <img src={InviteImg} alt="" className='w-44' />
                                <p className='text-sm my-2'>Bring your team on board by inviting them here.</p>
                                <button className='bg-primary text-sm text-white drop-shadow-sm p-2 rounded-lg hover:bg-primary/90 hover:scale-105'>
                                    Invite team members
                                </button>
                            </div>
                        }
                    </div>
                    <div className='my-4 w-11/12 mx-auto p-4 bg-white drop-shadow-sm'>
                        <h1 className='font-semibold'>Comming Soon</h1>
                        <div className='flex items-center my-8 space-x-2'>
                            <a href='/'><img src={Appstore} alt='' className="w-8 drop-shadow-sm hover:scale-105" /></a>
                            <a href='/'><img src={PlayStore} alt='' className="w-12 drop-shadow-sm hover:scale-110" /></a>
                            <a href='/'><img src={GExtension} alt='' className="w-10 drop-shadow-sm hover:scale-105" /></a>
                        </div>
                    </div>
                </motion.div>
        </motion.div>
    );
};

export default Drawer;
