const routePath = {
    HOME:'/',
    GETSTARTED:'/getstarted',
    ONBOARD:'/signup',
    DASHBOARD:'/dashboard',
    CREATEBRAND:'/create-brand',
    POSTS:'/posts',
    MESSAGES:'/messages',
    CHANNELS:'/channels',
    MONITOR:'/monitoring',
    REPORTS:'/reports',
    TEAM:'/team'
};


module.exports = routePath;