/* eslint-disable no-bitwise */

/**
 *
 * @description Generates an avatar from name initial
 *
 * */

export const createAvatar = (letters, size) => {
    let canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const diameter = size || 60;
  
    const color = generateColor(letters);
  
    // Set canvas with & height
    canvas.width = diameter;
    canvas.height = diameter;
  
    // Select a font family to support different language characters
    // like Arial
    context.font = Math.round(canvas.width / 2) + 'px Arial';
    context.textAlign = 'center';
  
    // Setup background and front color
    context.fillStyle = color;
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.fillStyle = '#FFF';
    context.fillText(letters.charAt(0), diameter / 2, diameter / 1.5);
  
    // Set image representation in default format (png)
    const dataURI = canvas.toDataURL();
  
    // Dispose canvas element
    canvas = null;
  
    return dataURI;
  };
  
  // Generate a random color
  
  const generateColor = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = '#';
    for (let j = 0; j < 3; j++) {
      const value = (hash >> (j * 8)) & 0xff;
      colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
  };