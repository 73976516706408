import routePath from "../../utils/constants";


export default function Hero() {

  return (
    <div className="bg-transparent">
      <div className="relative px-6 pt-14 lg:px-8">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
        <div className="mx-auto lg:w-7/12 lg:py-30 xs:my-48">
          {/* <div className="hidden sm:mb-8 sm:flex sm:justify-center">
          </div> */}
          <div className="text-center">
            <h1 className="lg:text-6xl font-bold tracking-tight text-white xs:text-4xl">
              Manage All your <span className="text-meta">Brand's</span> social channels from one platform with Weza Social.
            </h1>
            <p className="mt-6 xs:text-sm lg:text-lg mx-auto text-white w-10/12">
              Using the all-in-one administration platform provided by Weza Social, you can easily streamline the social channels associated with your brand
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6 border-1 border-primary">
              <a href={routePath.GETSTARTED} className="text-sm font-semibold leading-6 text-white bg-primary py-4 px-8 rounded cursor-pointer hover:scale-105">
                Get Started<span aria-hidden="true">&rarr;</span>
              </a>
            </div>
          </div>
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>
      </div>
    </div>
  )
}
